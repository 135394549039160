<template>
  <div><canvas :id="`planet-chart${className}`"></canvas></div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'PlanetChart',
  props: ['config','className'],
  data() {
    return {
      planetChartData: {
        type: 'line',
        data: this.config,
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                  padding: 25,
                },
              },
            ],
          },
        },
      },
    }
  },
  mounted() {
    // this.init()
  },
  methods: {
    init() {
      const name = `planet-chart${this.className}`
      const ctx = document.getElementById(name)
      new Chart(ctx, this.planetChartData)
    },
  },
  watch: {
    config: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue)
        this.init()
      },
      // immediate: true,
      deep: true
    },
  },
}
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>
