<template>
  <div class="infoDiv">
    <LoginReg />
    <div class="infoBox">
      <el-select
        v-model="symbolValue"
        placeholder="Select"
        no-data-text="No data"
        @change="changeSymbol"
      >
        <el-option
          v-for="item in symbolList"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <div class="baseInfo">
        <p class="title">Basic Information</p>
        <div class="baseInfoData">
          <p><span>baseCurrency</span> : {{ baseInfo.baseCurrency }}</p>
          <p><span>baseAmount</span> : {{ baseInfo.baseAmount }}</p>
          <p><span>quoteCurrency</span> : {{ baseInfo.quoteCurrency }}</p>
          <p><span>quoteAmount</span> : {{ baseInfo.quoteAmount }}</p>
          <p><span>avgBuyPrice</span> : {{ baseInfo.avgBuyPrice }}</p>
          <p><span>avgSellPrice</span> : {{ baseInfo.avgBuyPrice }}</p>
          <p><span>buyAmount</span> : {{ baseInfo.buyAmount }}</p>
          <p><span>sellAmount</span> : {{ baseInfo.sellAmount }}</p>
          <p><span>tradingUsers</span> : {{ baseInfo.tradingUsers }}</p>
          <p><span>userPositions</span> : {{ baseInfo.userPositions }}</p>
          <p><span>mmStrategy</span> : {{ baseInfo.mmStrategy }}</p>
        </div>
      </div>
      <div class="historyTrading">
        <p class="title">Historical Trading Statistics</p>
        <!-- <div>{{ historyInfo.tradingStats }}</div> -->
        <div>
          <div class="chartBox">
            <planet-chart :config="configData2" :className="2" />
            <planet-chart :config="configData3" :className="3" />
          </div>
          <planet-chart :config="configData" :className="1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginReg from '@/components/LoginRegister/index'
import { baseInfo, getSymbolList, historyTrading } from '@/api/interface'
import PlanetChart from '@/components/PlanetChart'

export default {
  data() {
    return {
      symbolValue: '',
      symbolList: [],
      baseInfo: '',
      historyInfo: '',
      configData: {
        labels: [],
        datasets: [],
      },
      configData2: {
        labels: [],
        datasets: [],
      },
      configData3: {
        labels: [],
        datasets: [],
      },
    }
  },
  components: { LoginReg, PlanetChart },
  created() {},
  mounted() {
    this.getSymbolListFun()
  },
  methods: {
    getSymbolListFun() {
      getSymbolList().then((res) => {
        this.symbolList = res.data
        this.symbolValue = res.data[0]
        this.baseInfoFun()
        this.historyTradingFun()
      })
    },
    changeSymbol() {
      this.baseInfoFun()
      this.historyTradingFun()
    },
    baseInfoFun() {
      const params = {
        symbol: this.symbolValue,
      }
      baseInfo(params).then((res) => {
        this.baseInfo = res.data
      })
    },
    historyTradingFun() {
      const params = {
        symbol: this.symbolValue,
      }
      historyTrading(params).then((res) => {
        this.historyInfo = res.data
        const labels = []
        const datasetsList = []
        const tradingUsers = []
        const sellAmount = []
        const buyAmount = []
        this.historyInfo.tradingStats?.reverse().forEach((item) => {
          labels.push(item.tradingDate)
          datasetsList.push(item.userPositions)
          tradingUsers.push(item.tradingUsers)
          sellAmount.push(item.sellAmount)
          buyAmount.push(item.buyAmount)
        })
        this.configData.labels = labels
        this.configData.datasets = [
          // {
          //   label: 'userPositions',
          //   data: datasetsList,
          //   backgroundColor: 'rgba(71, 183,132,.5)',
          //   borderColor: '#47b784',
          //   borderWidth: 3,
          // },
          // {
          //   label: 'tradingUsers',
          //   data: tradingUsers,
          //   backgroundColor: 'rgba(71, 13,132,.5)',
          //   borderColor: '#111',
          //   borderWidth: 3,
          // },
          {
            label: 'buyAmount',
            data: buyAmount,
            backgroundColor: 'rgba(6, 137, 65,.5)',
            borderColor: '#111',
            borderWidth: 3,
          },
          {
            label: 'sellAmount',
            data: sellAmount,
            backgroundColor: 'rgba(221, 29, 29,.5)',
            borderColor: '#111',
            borderWidth: 3,
          },
        ]
        console.log('this.configData', this.configData)

        this.configData2.labels = labels
        this.configData2.datasets = [
          {
            label: 'userPositions',
            data: datasetsList,
            backgroundColor: 'rgba(71, 183,132,.5)',
            borderColor: '#47b784',
            borderWidth: 3,
          },
        ]

        this.configData3.labels = labels
        this.configData3.datasets = [
          {
            label: 'tradingUsers',
            data: tradingUsers,
            backgroundColor: 'rgba(71, 13,132,.5)',
            borderColor: '#111',
            borderWidth: 3,
          },
        ]
      })
    },
  },
}
</script>
<style scoped lang="less">
.infoDiv {
  background: #fff;
  min-height: 100vh;
}
.infoBox {
  width: 1200px;
  margin: 0 auto;
  padding-top: 150px;
}
.baseInfo {
  line-height: 40px;

  .baseInfoData {
    color: #000;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    > p {
      width: 50%;
    }
    span {
      font-size: 18px;
      color: #494848;
    }
  }
}
.title {
  padding: 100px 0 20px 0;
  font-size: 24px;
}
.chartBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  >div {
    width: 50%;
  }
}
</style>
